import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/banner";
import OurService from "../components/ourservice";
import RecentWork from "../components/recentwork";
import Features from "../components/features";
import Testimonials from "../components/testimonials";
import RequestQuote from "../components/quote";
import Brands from "../components/brands";
import ImageSlider from "../components/gallery";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <OurService />
    {/* <RecentWork /> */}
    <Features />
    {/* <Testimonials /> */}
    <ImageSlider />
    <RequestQuote />
    <Brands />
  </Layout >
);

export default IndexPage;
