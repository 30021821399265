import React from 'react';
import { Link } from "gatsby";

const OurService = () => {

    const serviceList = [
        {
            url: "/interior-design/",
            title: "Interior Design",
            content: "Furniture layouts using new and existing furniture, Space planning and purchasing, Floor plans , Custom furniture, cabinet, and millwork design, Kitchen and bathroom design, Design services for clients with special needs"
        },
        {
            url: "/electrical-service/",
            title: "Electrical Service",
            content: "Designing, Residential, Industrial, Renewable Energy, Security System, Home Automation, Networking, Lightings"
        },
        {
            url: "#",
            title: "Painting Service",
            content: "Interior Painting, Exterior Painting, Renovations, Wallpaper Removal, Reconditioning Wood Siding & Decks, Pressure Washing , Staining, Residential or Commercial Painting, Flooring"
        },
        {
            url: "#",
            title: "Plumbing Service",
            content: "Plumbing Installation Services, Plumbing Pipe Upgrades, Drain and Sewer Line Services, Commercial Water Heater Services, System Maintenance and Cleaning,Kitchen, Bathroom and basement plumbing, Catch basin cleaning"
        },
        {
            url: "/electrical-service/",
            title: "Furnishing Service",
            content: "Sofas, Tables, Chairs, Beds, Desks, Mattresses, Dressers, Dining Tables, Dining Chairs, Sectional Sofas, TV Stands, Bookcases, Bunk Beds, Coffee Tables, Nightstands, Furniture Leg Styles, Arm Chair Styles"
        },
        {
            url: "#",
            title: "Home Maintenance Service",
            content: "Bathroom Deep Cleaning, Home cleaning, Kitchen cleaning, Ceiling fan cleaning, Exhaust fan cleaning, Fish tank cleaning services, Toilet cleaning, and lots more"
        },

    ];
    return (
        <section className="service-area section-gap" id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 pb-25 header-text text-center">
                        <h1 className="mb-10">What we offer to our clients</h1>
                        {/* <p>Who are in extremely love with eco friendly system..</p> */}
                    </div>
                </div>
                <div className="row">
                    {serviceList.map((service, index) => {
                        return (
                            <div key={index} className="col-lg-4 d-flex">
                                <div className="single-service">
                                    <h4>{service.title}</h4>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section >
    );
};
export default OurService;