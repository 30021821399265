import React from 'react';
import { Link } from "gatsby";
import ImageGallery from 'react-image-gallery';
import "../assets/css/image-gallery.css";

const images = [
    {
        original: 'https://i.imgur.com/8xKFOGP.jpg',
        thumbnail: 'https://i.imgur.com/8xKFOGP.jpg',
    },
    {
        original: 'https://i.imgur.com/uUhT84x.jpg',
        thumbnail: 'https://i.imgur.com/uUhT84x.jpg',
    },
    {
        original: 'https://i.imgur.com/SwN4xey.jpg',
        thumbnail: 'https://i.imgur.com/SwN4xey.jpg',
    },
    {
        original: 'https://i.imgur.com/VYAUvA8.jpg',
        thumbnail: 'https://i.imgur.com/VYAUvA8.jpg',
    },
    {
        original: 'https://i.imgur.com/DV78rsE.jpg',
        thumbnail: 'https://i.imgur.com/DV78rsE.jpg',
    },
    {
        original: 'https://i.imgur.com/I6la0s2.jpg',
        thumbnail: 'https://i.imgur.com/I6la0s2.jpg',
    },
    {
        original: 'https://i.imgur.com/NTjcoFv.jpg',
        thumbnail: 'https://i.imgur.com/NTjcoFv.jpg',
    },
    {
        original: 'https://i.imgur.com/u2ouh9B.jpg',
        thumbnail: 'https://i.imgur.com/u2ouh9B.jpg',
    },
    {
        original: 'https://i.imgur.com/nQgutd5.jpg',
        thumbnail: 'https://i.imgur.com/nQgutd5.jpg',
    },
    {
        original: 'https://i.imgur.com/LoNsKkw.jpg',
        thumbnail: 'https://i.imgur.com/LoNsKkw.jpg',
    },
    {
        original: 'https://i.imgur.com/60Xala1.jpg',
        thumbnail: 'https://i.imgur.com/60Xala1.jpg',
    },
    {
        original: 'https://i.imgur.com/P0mcHiD.jpg',
        thumbnail: 'https://i.imgur.com/P0mcHiD.jpg',
    },
    {
        original: 'https://i.imgur.com/R2PBgtN.jpg',
        thumbnail: 'https://i.imgur.com/R2PBgtN.jpg',
    },
    {
        original: 'https://i.imgur.com/4kj3H8w.jpg',
        thumbnail: 'https://i.imgur.com/4kj3H8w.jpg',
    },
    {
        original: 'https://i.imgur.com/3LemdyP.jpg',
        thumbnail: 'https://i.imgur.com/3LemdyP.jpg',
    },
    {
        original: 'https://i.imgur.com/tXzzrIF.jpg',
        thumbnail: 'https://i.imgur.com/tXzzrIF.jpg',
    },
    {
        original: 'https://i.imgur.com/ljrRIgD.jpg',
        thumbnail: 'https://i.imgur.com/ljrRIgD.jpg',
    },
    {
        original: 'https://i.imgur.com/WGh9CCy.jpg',
        thumbnail: 'https://i.imgur.com/WGh9CCy.jpg',
    },
    {
        original: 'https://i.imgur.com/p87XnAo.jpg',
        thumbnail: 'https://i.imgur.com/p87XnAo.jpg',
    },
    {
        original: 'https://i.imgur.com/2N9pnQT.jpg',
        thumbnail: 'https://i.imgur.com/2N9pnQT.jpg',
    },
    {
        original: 'https://i.imgur.com/2WoJv2e.jpg',
        thumbnail: 'https://i.imgur.com/2WoJv2e.jpg',
    },
    {
        original: 'https://i.imgur.com/jigabrC.jpg',
        thumbnail: 'https://i.imgur.com/jigabrC.jpg',
    },
    {
        original: 'https://i.imgur.com/rVzvcWP.jpg',
        thumbnail: 'https://i.imgur.com/rVzvcWP.jpg',
    },
    {
        original: 'https://i.imgur.com/yzTnNAP.jpg',
        thumbnail: 'https://i.imgur.com/yzTnNAP.jpg',
    },
    {
        original: 'https://i.imgur.com/lJZMwz9.jpg',
        thumbnail: 'https://i.imgur.com/lJZMwz9.jpg',
    },
    {
        original: 'https://i.imgur.com/UWllnX1.jpg',
        thumbnail: 'https://i.imgur.com/UWllnX1.jpg',
    }
];

class ImageSlider extends React.Component {
    render() {
        return (
            <section className="imageGallery">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="galleryTitle">
                                <h1>Here are some of our latest project images</h1>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <ImageGallery
                                items={images}
                                autoPlay={true}
                            />
                        </div>
                        {/* <div className="col-md-12 d-flex justify-content-center">
                            <Link className="primary-btn text-uppercase" to="/gallery/">Visit our gallery</Link>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default ImageSlider;